var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c(
              "nav",
              { staticClass: "nav sm-down:flex-column" },
              [
                _vm.type === "normal"
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                        attrs: { to: "/round/post" }
                      },
                      [
                        _c("i", { staticClass: "i-package float-left" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("new_round")))])
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                        attrs: { to: "/round/post/longvideo" }
                      },
                      [
                        _c("i", { staticClass: "i-package float-left" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("new_video")))])
                      ]
                    ),
                _vm._v(" "),
                _vm.type === "normal"
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:d-none",
                        attrs: {
                          to: {
                            name: _vm.user_id ? "User_Map" : "Rounds_Map",
                            params: {
                              user_id: _vm.user_id,
                              type: _vm.type
                            }
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "i-map float-left" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("open_map")))])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                    attrs: {
                      to: {
                        name: "Rounds",
                        params: { type: _vm.type }
                      }
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.clearLoadPage(_vm.loadPage)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "i-grid float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("open_list")))])
                  ]
                ),
                _vm._v(" "),
                _vm.type === "normal"
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:d-none",
                        attrs: {
                          to: {
                            path: "/rounds/" + _vm.type + "/fav"
                          }
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.clearLoadPage(_vm.loadFav)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "i-star-solid float-left" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("round_favs")))])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:d-none",
                    attrs: {
                      to: {
                        path: "/rounds/" + _vm.type + "/promoted"
                      }
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.clearLoadPage(_vm.loadPromoted)
                      }
                    }
                  },
                  [
                    _c(
                      "IconBase",
                      {
                        staticStyle: { "margin-right": "0" },
                        attrs: {
                          height: "15",
                          width: "15",
                          viewBox: "0 0 800 800"
                        }
                      },
                      [_c("Promoted")],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v(_vm._s(_vm.$t("rounds_promoted")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                    attrs: {
                      to: {
                        path: _vm.categoriesPath
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "i-folder float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("categories")))])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { "flex-grow": "1" } }),
                _vm._v(" "),
                _vm.type !== "long"
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                        on: {
                          click: function($event) {
                            return _vm.$refs["advert-settings"].open()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("advertising_company")) +
                            "\n          "
                        ),
                        _c(
                          "IconBase",
                          {
                            staticClass: "mr-0",
                            attrs: {
                              height: "13.632",
                              width: "14",
                              viewBox: "0 0 512 512"
                            }
                          },
                          [_c("Advertising")],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isProduction
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                        on: {
                          click: function($event) {
                            return _vm.$refs["mass-recommend"].open()
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "i-trending-up float-left" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("round_mass_recommend")))
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selected.length > 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "btn btn-sm btn-primary p-0 d-block sm-down:w-fill",
                        attrs: { "data-click": "ignore" },
                        on: { click: _vm.download_confirm }
                      },
                      [
                        _c(
                          "IconBase",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: {
                              height: "13.632",
                              width: "14",
                              viewBox: "0 0 27.02 26.31"
                            }
                          },
                          [_c("Download")],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selected.length > 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "ml-2 btn btn-sm btn-danger p-0 d-block sm-down:w-fill",
                        attrs: { "data-click": "ignore" },
                        on: { click: _vm.delete_confirm }
                      },
                      [
                        _c(
                          "IconBase",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: {
                              height: "13.632",
                              width: "14",
                              viewBox: "0 0 28 33"
                            }
                          },
                          [_c("Delete")],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container py-5" },
          [
            _vm.list.length
              ? _c(
                  _vm.$route.name == "Rounds_Promoted" ? "draggable" : "div",
                  {
                    tag: "component",
                    staticClass: "row",
                    attrs: { animation: 200 },
                    on: {
                      update: function($event) {
                        return _vm.changePromotedOrder($event)
                      }
                    },
                    model: {
                      value: _vm.list,
                      callback: function($$v) {
                        _vm.list = $$v
                      },
                      expression: "list"
                    }
                  },
                  _vm._l(_vm.list, function(round) {
                    return _c(
                      "div",
                      {
                        key: round.idt_round,
                        ref: "col-" + round.idt_round,
                        refInFor: true,
                        staticClass:
                          "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _vm.type === "normal"
                              ? _vm._o(
                                  _c("round-card", {
                                    attrs: { data: round, selectable: "" },
                                    on: {
                                      "select-force": _vm.onSelectForce,
                                      remove: _vm.onRemove,
                                      select: _vm.onSelect
                                    }
                                  }),
                                  0,
                                  round.idt_round
                                )
                              : _c("video-card", {
                                  attrs: { data: round, selectable: "" },
                                  on: {
                                    "select-force": _vm.onSelectForce,
                                    remove: _vm.onRemove,
                                    select: _vm.onSelect
                                  }
                                })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.list.length >= 0
              ? _c("mega-loading", {
                  attrs: { identifier: _vm.loadingId },
                  on: { infinite: _vm.mainFunc }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "delete-confirm",
        {
          ref: "delete-confirm",
          attrs: {
            "data-click": "ignore",
            title: _vm.$t("round_del_title"),
            text: _vm.$t("round_del_confirm"),
            rounds: _vm.selected
          },
          on: { confirm: _vm.delete_rounds }
        },
        _vm._l(_vm.selected, function(id, i) {
          return _c("span", { key: i, staticClass: "badge m-2" }, [
            _vm._v("#" + _vm._s(id))
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "download-confirm",
        {
          ref: "download-confirm",
          attrs: {
            "data-click": "ignore",
            title: _vm.$t("round_download_title"),
            text: _vm.$t("round_download_confirm"),
            rounds: _vm.selected
          },
          on: { confirm: _vm.download_rounds }
        },
        _vm._l(_vm.selected, function(id, i) {
          return _c("span", { key: i, staticClass: "badge m-2" }, [
            _vm._v("#" + _vm._s(id))
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("advert-settings", { ref: "advert-settings" }),
      _vm._v(" "),
      _c("mass-recommend", { ref: "mass-recommend" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }