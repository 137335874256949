<template>
  <main class="models-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <router-link
            v-if="type === 'normal'"
            to="/round/post"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-package float-left"></i>
            <span>{{ $t("new_round") }}</span>
          </router-link>
          <router-link
            v-else
            to="/round/post/longvideo"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-package float-left"></i>
            <span>{{ $t("new_video") }}</span>
          </router-link>

          <router-link
            v-if="type === 'normal'"
            :to="{
              name: user_id ? 'User_Map' : 'Rounds_Map',
              params: {
                user_id: user_id,
                type: type
              }
            }"
            class="btn btn-sm btn-light text-left d-block sm-down:d-none"
          >
            <i class="i-map float-left"></i>
            <span>{{ $t("open_map") }}</span>
          </router-link>

          <router-link
            :to="{
              name: 'Rounds',
              params: { type: type }
            }"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
            @click.native="clearLoadPage(loadPage)"
          >
            <i class="i-grid float-left"></i>
            <span>{{ $t("open_list") }}</span>
          </router-link>

          <router-link
            v-if="type === 'normal'"
            :to="{
              path: '/rounds/' + type + '/fav'
            }"
            class="btn btn-sm btn-light text-left d-block sm-down:d-none"
            @click.native="clearLoadPage(loadFav)"
          >
            <i class="i-star-solid float-left"></i>
            <span>{{ $t("round_favs") }}</span>
          </router-link>
          <router-link
            :to="{
              path: '/rounds/' + type + '/promoted'
            }"
            class="btn btn-sm btn-light text-left d-block sm-down:d-none"
            @click.native="clearLoadPage(loadPromoted)"
          >
            <IconBase
              :height="'15'"
              :width="'15'"
              :viewBox="'0 0 800 800'"
              style="margin-right: 0"
              ><Promoted
            /></IconBase>
            <span class="ml-1">{{ $t("rounds_promoted") }}</span>
          </router-link>

          <router-link
            :to="{
              path: categoriesPath
            }"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-folder float-left"></i>
            <span>{{ $t("categories") }}</span>
          </router-link>

          <div style="flex-grow: 1" />

          <span
            v-if="type !== 'long'"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
            @click="$refs['advert-settings'].open()"
          >
            {{ $t("advertising_company") }}
            <IconBase
              :height="'13.632'"
              :width="'14'"
              :viewBox="'0 0 512 512'"
              class="mr-0"
            >
              <Advertising />
            </IconBase>
          </span>

          <span
            v-if="!isProduction"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
            @click="$refs['mass-recommend'].open()"
          >
            <i class="i-trending-up float-left"></i>
            <span>{{ $t("round_mass_recommend") }}</span>
          </span>

          <span
            class="btn btn-sm btn-primary p-0 d-block sm-down:w-fill"
            data-click="ignore"
            @click="download_confirm"
            v-if="selected.length > 0"
          >
            <IconBase
              :height="'13.632'"
              :width="'14'"
              :viewBox="'0 0 27.02 26.31'"
              style="margin-right: 0"
              ><Download
            /></IconBase>
          </span>

          <span
            class="ml-2 btn btn-sm btn-danger p-0 d-block sm-down:w-fill"
            data-click="ignore"
            @click="delete_confirm"
            v-if="selected.length > 0"
          >
            <IconBase
              :height="'13.632'"
              :width="'14'"
              :viewBox="'0 0 28 33'"
              style="margin-right: 0"
              ><Delete
            /></IconBase>
          </span>
        </nav>
      </div>
    </section>

    <section>
      <div class="container py-5">
        <component
          v-model="list"
          :is="$route.name == 'Rounds_Promoted' ? 'draggable' : 'div'"
          :animation="200"
          class="row"
          v-if="list.length"
          @update="changePromotedOrder($event)"
        >
          <div
            class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
            v-for="round in list"
            :ref="'col-' + round.idt_round"
            :key="round.idt_round"
          >
            <keep-alive>
              <round-card
                v-if="type === 'normal'"
                v-once
                :data="round"
                selectable
                @select-force="onSelectForce"
                @remove="onRemove"
                @select="onSelect"
              />
              <video-card
                v-else
                :data="round"
                selectable
                @select-force="onSelectForce"
                @remove="onRemove"
                @select="onSelect"
              />
            </keep-alive>
          </div>
        </component>

        <mega-loading
          v-if="list.length >= 0"
          :identifier="loadingId"
          @infinite="mainFunc"
        ></mega-loading>
      </div>
    </section>

    <delete-confirm
      ref="delete-confirm"
      data-click="ignore"
      :title="$t('round_del_title')"
      :text="$t('round_del_confirm')"
      @confirm="delete_rounds"
      :rounds="selected"
    >
      <span class="badge m-2" v-for="(id, i) in selected" :key="i"
        >#{{ id }}</span
      >
    </delete-confirm>

    <download-confirm
      ref="download-confirm"
      data-click="ignore"
      :title="$t('round_download_title')"
      :text="$t('round_download_confirm')"
      @confirm="download_rounds"
      :rounds="selected"
    >
      <span class="badge m-2" v-for="(id, i) in selected" :key="i"
        >#{{ id }}</span
      >
    </download-confirm>
    <advert-settings ref="advert-settings" />
    <mass-recommend ref="mass-recommend" />
  </main>
</template>

<script>
import RoundCard from "./components/round-card";
import VideoCard from "./components/video-card";
import AdvertSettings from "./components/advert-settings";
import MassRecommend from "./components/mass-recommend";
import DeleteConfirm from "../../components/delete-confirm";
import DownloadConfirm from "../../components/download-confirm";
import IconBase from "../../../components/icons/IconBase.vue";
import Download from "../../../components/icons/set/Download.vue";
import Delete from "../../../components/icons/set/Delete.vue";
import Promoted from "../../../components/icons/set/Promoted.vue";
import Advertising from "../../../components/icons/set/Advertising.vue";
import draggable from "vuedraggable";

export default {
  name: "RoundsPage",
  props: {
    type: String,
    user_id: [Number, String],
    arr_id: [Number, String]
  },
  data() {
    return {
      selected: [],
      keys: {},
      showModal: false,
      multiple: false,
      loadingId: +new Date(),
      list: [],
      mainFunc: "",
      isProduction: process.env.NODE_ENV === "production"
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.type !== from.params.type) {
        this.clearLoadPage(this.loadPage);
      }
    }
  },
  computed: {
    categoriesPath() {
      if (this.type === "normal") return "/categories/rounds";
      else return "/categories/videos";
    }
  },
  mounted() {
    this.$navbar.name = this.$t("rounds");

    if (this.$route.name == "Rounds") {
      this.mainFunc = this.loadPage;
    } else if (this.$route.name == "Rounds_Fav") {
      this.mainFunc = this.loadFav;
    } else if (this.$route.name == "Rounds_Promoted") {
      this.mainFunc = this.loadPromoted;
    }

    document.addEventListener("click", e => {
      let p = e.target;

      while (p) {
        if (
          (p && p.dataset.type === "round-card") ||
          (p && p.dataset.click === "ignore")
        )
          return;

        p = p.parentElement;
      }

      this.selected = [];
    });

    document.addEventListener("keyup", this.onKeyup);
    document.addEventListener("keydown", this.onKeydown);
  },
  beforeDestroy() {
    document.removeEventListener("click", () => {});

    document.removeEventListener("keyup", this.onKeyup);
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    updateNavbar() {
      this.$api.v2base
        .get("/round/count", {})
        .then(({ data }) => {
          this.length = data.count;
          this.$navbar.name = `${this.$t("rounds")} (${this.length})`;
        })
        .catch(({ response }) => {
          if (response.status === 403) this.$state.complete();
          else this.$alert.danger("Oops.. Server error");
        });
    },

    changePromotedOrder(e) {
      console.log(e.oldIndex, e.newIndex);
      console.log(this.list[e.newIndex]);
      let body = {
        idt_round_after: this.list[e.newIndex - 1]
          ? this.list[e.newIndex - 1].idt_round
          : 0,
        idt_round: this.list[e.newIndex].idt_round,
        sn_id: this.$store.getters.selectedSnId
      };
      this.$api.v2base
        .put("/round/promoted", body)
        .then(() => {
          this.$alert.success(this.$t("round_likes_success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },

    onKeyup(e) {
      this.keys[e.key] = false;
    },
    onKeydown(e) {
      this.keys[e.key] = true;
    },

    delete_confirm() {
      this.$refs["delete-confirm"].open();
    },

    download_confirm() {
      this.$refs["download-confirm"].open();
    },

    onSelect(roundId) {
      if (!this.keys["Control"]) this.selected = [];

      if (!this.selected.includes(roundId)) this.selected.push(roundId);
    },

    onRemove(roundId) {
      if (!this.keys["Control"]) this.onSelect(roundId);
      else this.selected = this.selected.filter(r => r !== roundId);
    },

    onSelectForce(roundId) {
      if (!this.selected.includes(roundId)) this.selected.push(roundId);
      else this.selected = this.selected.filter(r => r !== roundId);
    },

    async delete_rounds() {
      for (const id of this.selected) {
        await this.$api.v2.delete("/round", { params: { idt_round: id } });
        this.list = this.list.filter(el => el.idt_round !== id);
        this.$refs["col-" + id][0].outerHTML = "";
      }

      this.selected.splice(0, this.selected.length);

      this.$refs["delete-confirm"].close();
    },

    async download_rounds() {
      let selected_media = this.list.filter(item => {
        return this.selected.includes(item["idt_round"]);
      });
      let media = selected_media.map(item =>
        item.media
          ? [
              item.media.url,
              "[user=" +
                item.profile.username +
                "]_[date=" +
                this.$u2d(item["a_time"], "DD-MM-YYYY") +
                "]_[link=" +
                this.$router
                  .resolve("round_" + item["idt_round"])
                  .href.replace("/", "_") +
                "]." +
                item.media.url.split(".")[item.media.url.split(".").length - 1]
            ]
          : [
              item["url_preview"],
              "[user=" +
                item.profile.username +
                "]_[date=" +
                this.$u2d(item["a_time"], "DD-MM-YYYY") +
                "]_[link - " +
                this.$router
                  .resolve("round_" + item["idt_round"])
                  .href.replace("/", "_") +
                "]." +
                item["url_preview"].split(".")[
                  item["url_preview"].split(".").length - 1
                ]
            ]
      );
      fetch("/downloadRounds", {
        method: "POST",
        body: JSON.stringify(media),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(async response => {
        let blob = await response.blob();
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        (a.href = url),
          (a.download = `rounds_arround_${this.$u2d(
            new Date(),
            "DD-MM-YYYY"
          )}.zip`);
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      });
    },

    clearLoadPage(callback) {
      this.list = [];
      this.$root.list = [];
      this.loadingId = +new Date();
      this.mainFunc = callback;
    },

    loadFav($state) {
      let params = {
        limit: 20,
        idt_arp: this.arr_id,
        idt_user: this.user_id,
        offset: this.list.length,
        is_location_hidden: true,
        in_favourites: true,
        long_video: this.type !== "normal",
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/rounds", { params })
        .then(({ data }) => {
          if (data.rounds.length) {
            setTimeout(() => {
              if ($state) $state.loaded();
            }, 500);
            this.list.push(...data.rounds);
          } else $state.complete();
        })
        .catch(({ response }) => {
          if (response.status === 404) $state.complete();
          else this.$alert.danger("Oops.. Server error");
        });
    },

    loadPromoted($state) {
      let params = {
        limit: 20,
        idt_arp: this.arr_id,
        idt_user: this.user_id,
        offset: this.list.length,
        is_location_hidden: true,
        long_video: this.type !== "normal",
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2base
        .get("/round/promoted", { params })
        .then(({ data }) => {
          if (data.length) {
            setTimeout(() => {
              if ($state) $state.loaded();
            }, 500);
            this.list.push(...data);
          } else $state.complete();
        })
        .catch(({ response }) => {
          if (response.status === 404) $state.complete();
          else this.$alert.danger("Oops.. Server error");
        });
    },

    loadPage($state) {
      let params = {
        limit: 20,
        idt_arp: this.arr_id,
        idt_user: this.user_id,
        offset: this.list.length,
        is_location_hidden: true,
        long_video: this.type !== "normal",
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/rounds", { params })
        .then(({ data }) => {
          if (data.rounds.length) {
            setTimeout(() => {
              if ($state) $state.loaded();
            }, 500);
            this.list.push(...data.rounds);
          } else $state.complete();
        })
        .catch(({ response }) => {
          if (response.status === 404) $state.complete();
          else this.$alert.danger("Oops.. Server error");
        });
    }
  },
  components: {
    RoundCard,
    VideoCard,
    DeleteConfirm,
    DownloadConfirm,
    IconBase,
    Download,
    Delete,
    Promoted,
    draggable,
    Advertising,
    AdvertSettings,
    MassRecommend
  }
};
</script>

<style lang="sass" scoped>
.router-link-exact-active
  background: #028cff
  color: rgba(255, 255, 255, 0.9)
</style>
