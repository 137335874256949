<template>
  <mega-modal
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <div class="card-body p-4" @keypress.enter="submit">
      <mega-switch
        class="w-100 mb-5 bg-success mt-3"
        :label="$t('round_top_forall')"
        @click="toggleForAll"
        :checked="topForAll"
      />

      <span class="form-label"> {{ $t("round_recommend_number") }} </span>
      <div class="d-flex flex-column">
        <mega-range :min="1" :max="100" :step="1" v-model="amount" />
        <mega-number :min="1" :max="100" :step="1" v-model="amount" />
      </div>

      <div class="navbar sm-down:flex-column-reverse md-up:justify-content-end">
        <mega-button
          class="bg-white sm-down:w-100 md-up:mr-2"
          @click="modal = false"
          >{{ $t("close") }}</mega-button
        >
        <mega-button
          class="btn-primary sm-down:w-100 sm-down:mb-3"
          @click="submit"
          >{{ $t("confirm") }}</mega-button
        >
      </div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      topForAll: true,
      amount: 20
    };
  },
  methods: {
    async submit() {
      const getRoundIds = amount => {
        return this.$api.v2
          .get("/rounds", {
            limit: amount
          })
          .then(response => {
            return response.data.rounds.map(round => round.idt_round);
          })
          .catch(() => {
            this.$alert.danger(this.$t("err_server"));
          });
      };

      const roundIds = await getRoundIds(this.amount);

      roundIds.slice(0, this.amount).forEach(id => {
        const data = {
          idt_round: Number(id),
          for_all: this.topForAll,
          idc_sex: 1
        };

        this.$api.v2base.post("/round/explore/on_top", data).catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
      });

      this.close();
      this.$alert.success(this.$t("success"));
    },

    toggleForAll() {
      this.topForAll = !this.topForAll;
    },

    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    }
  }
};
</script>
