<template>
  <mega-modal
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <div class="card-body p-4" @keypress.enter="submit">
      <span class="form-label mr-3"> {{ $t("advert_number") }} </span>
      <div class="d-flex flex-column pt-2">
        <mega-range :min="1" :max="100" :step="1" v-model="frequency" />
        <mega-number
          :min="1"
          :max="100"
          :step="1"
          v-model="frequency"
          :help="
            'На X обычных раундов будет приходиться один рекламный, где X - выбранное число'
          "
        />
      </div>
      <div class="navbar sm-down:flex-column-reverse md-up:justify-content-end">
        <mega-button
          class="bg-white sm-down:w-100 md-up:mr-2"
          @click="modal = false"
          >{{ $t("close") }}</mega-button
        >
        <mega-button
          class="btn-primary sm-down:w-100 sm-down:mb-3"
          @click="submit"
          >{{ $t("confirm") }}</mega-button
        >
      </div>
    </div>
  </mega-modal>
</template>

<script>
import { DatePicker } from "element-ui";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/button.css";
import ru from "element-ui/lib/locale/lang/ru-RU";
import en from "element-ui/lib/locale/lang/en";
export default {
  props: ["round", "enabled", "likes"],
  components: {
    DatePicker
  },
  data() {
    return {
      modal: false,
      frequency: undefined
    };
  },
  mounted() {
    locale.use(this.$i18n.locale === "ru" ? ru : en);
    this.$api.v2base.get("round/globals/adcomp").then(res => {
      this.frequency = res.data.frequency;
    });
  },
  methods: {
    submit() {
      this.$api.v2base
        .put("round/globals/adcomp", {
          frequency: this.frequency
        })
        .then(() => {
          this.close();
          this.$alert.success(this.$t("success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("danger"));
        });
    },

    open() {
      this.modal = true;
      this.$api.v2base.get("round/globals/adcomp").then(res => {
        this.frequency = res.data.frequency;
      });
    },

    close() {
      this.modal = false;
    }
  }
};
</script>
