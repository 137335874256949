<template>
  <mega-card
    class="card-border card-round rounded"
    :class="{ 'overlay-10 active': isSelected }"
    :style="{
      background: isSelected && 'background: #eff5fd !important',
      'background-color': beingPromoted ? 'rgba(127, 205, 200, 0.39)' : 'white'
    }"
    style="position: initial"
  >
    <div class="navbar py-3 user-container" v-if="!noAuthor">
      <div class="container-fluid">
        <span
          class="btn p-0 checker"
          @click.stop="$emit('select-force', id)"
          v-if="selectable"
          data-click="ignore"
        >
          <IconBase
            v-if="isSelected"
            :height="'13.632'"
            :width="'14'"
            :viewBox="'0 0 34 34'"
            class="mr-0"
            ><FullfilledCheck
          /></IconBase>

          <IconBase
            v-else
            :height="'13.632'"
            :width="'14'"
            :viewBox="'0 0 34 34'"
            class="mr-0"
            ><EmptyCheck
          /></IconBase>
        </span>
        <router-link
          :to="{ name: type, params: { id: author.id } }"
          class="navbar-label w-100 text-dark"
        >
          <mega-image
            class="rounded-full navbar-avatar mr-3"
            ratio="1x1"
            small
            :src="author.avatar"
            style="overflow:hidden;"
          />

          <div class="content d-flex" style="width: calc(100% - 78px);">
            <small class="strong text-overflow">
              <i
                v-if="type === 'Business'"
                class="i-globe text-muted text-arround align-middle mr-1"
              ></i>
              <span
                >{{ author.name }}
                <IconBase
                  v-if="author.verified"
                  viewBox="0 0 312 311"
                  iconColor="#45C1C9"
                  ><UserVerified /></IconBase
              ></span>
            </small>
            <div class="d-flex align-items-center">
              <small class="text-muted text-overflow"
                >@{{ author.username }}
              </small>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <span v-if="data.category" class="container-fluid pt-0 pb-3">
      <small>
        Category:
      </small>
      <div class="category small" style="height: 24px">
        <span class="small">{{
          data.category ? data.category.label_ru : ""
        }}</span>
        <img
          :src="data.category.icon"
          class="small"
          alt=""
          style="max-height: 15px"
        />
      </div>
    </span>
    <span v-else class="container-fluid pt-0 pb-3"></span>

    <div class="media p-relative">
      <div class="card-media card-video" v-if="video_link">
        <mega-image ratio="3x2" :src="preview" />

        <div class="media-overlay overlay-50 overlay-dark">
          <div class="text-center">
            <span class="sticker sticker-lg bg-white"
              ><i class="i-play"></i
            ></span>
          </div>
        </div>

        <div
          class="media-overlay overlay-90 overlay-dark media-video"
          @mouseenter="play"
          @mouseleave="pause"
        >
          <router-link
            class="p-absolute fill-parent z-3"
            :to="'/round/' + id"
          ></router-link>

          <video
            ref="video"
            class="video-player fill-parent h-100"
            style="object-fit: cover"
            :src="video_link"
            :poster="preview"
            preload="none"
            loop
            muted
          ></video>
        </div>
      </div>

      <div class="card-media" v-if="!video_link">
        <div class="media-overlay overlay-50 overlay-dark" v-if="youtube">
          <div class="text-center">
            <span class="sticker sticker-lg bg-white"
              ><i class="i-youtube-solid"></i
            ></span>
          </div>
        </div>

        <router-link
          class="p-absolute fill-parent z-3"
          :to="'/round/' + id"
        ></router-link>

        <mega-image ratio="3x2" :src="preview" />
      </div>
    </div>

    <div data-click="ignore">
      <nav class="nav font-small d-flex justify-content-between">
        <div class="d-flex">
          <span
            class="btn mx-0 pr-2"
            style="font-size: 10px; text-overflow: initial"
            ><i class="i-eye" style="font-size: 14px"></i> {{ views }}</span
          >
          <input
            class="likes-input align-self-center"
            style="width: 7ch; height: 3ch"
            type="number"
            :placeholder="$t('round_views_input')"
            @click.stop
            @keydown.enter="changeViews"
          />

          <span
            class="btn mx-0 pr-2"
            style="font-size: 10px; text-overflow: initial"
            ><i class="i-heart" style="font-size: 14px"></i> {{ likes }}</span
          >
          <input
            class="likes-input align-self-center"
            style="width: 7ch; height: 3ch"
            type="number"
            :placeholder="$t('round_likes_input')"
            @click.stop
            @keydown.enter="changeLikes"
          />
        </div>
        <div class="d-flex mt-1">
          <span @click.stop="downloadRound" class="p-0 btn btn-sm">
            <IconBase
              :height="'13.632'"
              :width="'14'"
              :viewBox="'0 0 27.02 26.31'"
              class="mr-0"
              ><Download
            /></IconBase>
          </span>
          <router-link @click.stop class="btn p-0 btn-sm" :to="'/round/' + id">
            <IconBase
              :height="'13.632'"
              :width="'14'"
              :viewBox="'0 0 26 34'"
              class="mr-0"
              ><GoTo
            /></IconBase>
          </router-link>
        </div>
      </nav>

      <nav class="nav flex-column font-small">
        <div class="nav-text d-flex">
          <h4 class="p-0 text-overflow" style="margin: auto;">
            {{ title }}
          </h4>
          <div style="flex-grow: 1"></div>
          <div style="font-size: 8px;">
            <span style="vertical-align: sub">{{
              date | u2d("DD.MM.YYYY LT")
            }}</span>
          </div>
        </div>
        <div
          style="display: -webkit-box;
                    -webkit-line-clamp: 2;
                    height: 3rem;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;"
          class="px-3 pb-2"
        >
          {{ name }}
        </div>
        <div
          class="nav-text text-muted d-flex justify-content-end align-items-center mb-1 pl-2 pr-2"
        >
          <mega-button
            v-if="readyForPush || !promoted_at || !promoted"
            :disabled="advert != ''"
            :class="{ 'btn-light': advert }"
            class="btn btn-sm btn-primary overlay-10 align-self-start mx-2"
            @click="$refs['to-top'].open()"
          >
            {{ advert ? $t("ads_round") : $t("round_to_top") }}
          </mega-button>
          <div v-else style="height: 30px; margin-right: 6px">
            <div
              class="btn btn-sm z-3 text-white align-self-start"
              style="background: rgba(0,0,0,0.75); user-select: none;"
              onMouseOver="this.style.color='#FFFFFF'"
            >
              {{ timeUntilPushReady }}
            </div>
            <div
              @click.stop="$refs['delete-top-confirm'].open()"
              class="btn btn-sm btn-light text-danger"
              style="text-overflow: initial"
            >
              <IconBase
                :height="'13.632'"
                :width="'14'"
                :viewBox="'0 0 28 33'"
                style="margin-right: 0; overflow: none"
                ><Delete
              /></IconBase>
            </div>
          </div>
          <div style="flex-grow: 1"></div>
          <IconBase
            class="d-inline"
            v-if="promoted_at"
            :height="'15'"
            :width="'15'"
            :viewBox="'0 0 800 800'"
            :style="
              'margin-left: auto !important; margin-right: 0px; min-width: 15px'
            "
            iconColor="#45C1C9"
            ><Promoted
          /></IconBase>
        </div>
      </nav>
    </div>
    <delete-confirm
      ref="delete-top-confirm"
      data-click="ignore"
      :title="$t('round_del_top_title')"
      :text="$t('round_del_top_confirm')"
      @confirm="deleteFromTop"
    >
      <span class="badge m-2">#{{ id }}</span>
    </delete-confirm>
    <to-top
      ref="to-top"
      :round="id"
      :likes="Number(likes)"
      :enabled="true"
      @refreshRound="refreshRound"
      @click.stop
    />
  </mega-card>
</template>

<script>
import IconBase from "../../../../components/icons/IconBase.vue";
import UserVerified from "../../../../components/icons/set/UserVerified.vue";
import Download from "../../../../components/icons/set/Download.vue";
import Promoted from "../../../../components/icons/set/Promoted.vue";
import Delete from "../../../../components/icons/set/Delete.vue";
import GoTo from "../../../../components/icons/set/GoTo.vue";
import EmptyCheck from "../../../../components/icons/set/EmptyCheck.vue";
import FullfilledCheck from "../../../../components/icons/set/FullfilledCheck.vue";
import DeleteConfirm from "../../../components/delete-confirm";
import ToTop from "./to-top";

export default {
  props: {
    data: Object,
    noAuthor: Boolean,
    selectable: Boolean
  },
  components: {
    IconBase,
    UserVerified,
    Download,
    GoTo,
    EmptyCheck,
    FullfilledCheck,
    ToTop,
    DeleteConfirm,
    Delete,
    Promoted
  },

  mounted() {
    if (this.promoted_at && this.promoted_at.endsWith("Z")) {
      let date = Date.parse(this.promoted_at.replace(/Z/g, ""));
      this.validatePushToTop(date);
    }
  },

  beforeDestroy() {
    clearInterval(this.timerLastPush);
  },

  data() {
    return {
      title: this.data.title,
      name: this.data.message,
      id: this.data["idt_round"],
      date: this.data["a_time"],
      preview: this.data["url_preview"],
      views: this.data["view_count"],
      likes: this.data.likes["likes_count"],
      dislikes: this.data.likes["dislikes_count"],
      comments: this.data["comments_count"],
      address: this.data["place_address"],
      media: this.data["media"],
      videos: this.data["videos"],
      youtube: this.data["youtube"] || undefined,
      is_location_hidden: this.data["is_location_hidden"],
      promoted_at: this.data["promoted_at"],
      promoted: this.data["promoted"],
      readyForPush: false,
      timeUntilPushReady: "",
      timerLastPush: -1,
      author: {
        id: this.data.from.id,
        type: this.data.from.type,
        name: this.data.from.name,
        avatar: this.data.from.avatar,
        username:
          this.data.from.username ||
          this.data.from.type + "_" + this.data.from.id,
        verified: this.data.from.verified
      },
      advert: this.data.adcomp || ""
    };
  },
  computed: {
    type() {
      let type = undefined;

      if (this.author.type === "user") type = "User";
      if (this.author.type === "arp") type = "Business";

      return type;
    },

    video_link() {
      return this.media
        ? this.media["url"]
        : this.videos
        ? this.videos[0]["url"]
        : null;
    },

    isSelected() {
      let _selected = false;

      if (this.$parent.selected)
        _selected = this.$parent.selected.includes(this.id);

      return _selected;
    },

    fileName() {
      return !this.media
        ? "[user=" +
            this.author.username +
            "]_[date=" +
            this.$u2d(this.date, "DD-MM-YYYY") +
            "]_[link=" +
            this.$router.resolve("round_" + this.id).href.replace("/", "_") +
            "]." +
            this.preview.split(".")[this.preview.split(".").length - 1]
        : "[user=" +
            this.author.username +
            "]_[date=" +
            this.$u2d(this.date, "DD-MM-YYYY") +
            "]_[link=" +
            this.$router.resolve("round_" + this.id).href.replace("/", "_") +
            "]." +
            this.media.url.split(".")[this.media.url.split(".").length - 1];
    },

    beingPromoted() {
      return !(this.readyForPush || !this.promoted_at || !this.promoted);
    }
  },
  methods: {
    deleteFromTop() {
      this.$api.v2base
        .delete("/round/promoted", {
          params: {
            idt_round: this.id,
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(() => {
          this.$alert.success(this.$t("round_del_from_top"));
          this.$refs["delete-top-confirm"].close();
          this.promoted = false;
          clearInterval(this.timerLastPush);
        });
    },
    refreshRound(likes) {
      this.promoted = true;
      if (likes) {
        this.likes = likes;
      }
      this.promoted_at = new Date();
      this.validatePushToTop(
        this.promoted_at.setHours(this.promoted_at.getHours() - 3)
      );
    },

    validatePushToTop(timestampLast) {
      const timestampNow = new Date().getTime();
      this.readyForPush =
        timestampNow - timestampLast >= 1000 * 60 * 60 * 24 * 7 ? true : false;
      const dateReady =
        1000 * 60 * 60 * 24 * 7 + timestampLast + 1000 * 60 * 60 * 3;
      this.timeUntilPushReady = this.getDate(dateReady);
      this.timerLastPush = setInterval(() => {
        this.timeUntilPushReady = this.getDate(dateReady);
      }, 1000);
    },

    getDate(future) {
      const now = new Date().getTime();
      const distance = future - now;

      if (distance < 0) {
        clearInterval(this.timerLastPush);
      }

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let appendix =
        days >= 5
          ? this.$t("round_to_top_day_1")
          : days > 1
          ? this.$t("round_to_top_day_2")
          : days == 0
          ? this.$t("round_to_top_day_1")
          : this.$t("round_to_top_day_3");
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      return `${days} ${appendix} ${hours >= 10 ? hours : "0" + hours}:${
        minutes >= 10 ? minutes : "0" + minutes
      }:${seconds >= 10 ? seconds : "0" + seconds}`;
    },

    onClick() {
      if (!this.isSelected) {
        if (window && window.isMobileOrTablet()) this.open();
        else this.$emit("select", this.id);
      } else this.$emit("remove", this.id);
    },

    open() {
      this.$router.push("/round/" + this.id);
    },

    updateAdvert(advert) {
      this.data.adcomp = advert;
      this.advert = advert;
      console.log("🤦‍♀️🤦‍♀️🤦‍♀️", this.advert);
    },

    changeViews(e) {
      let params = {
        idt_round: this.id,
        view_count: Number(e.target.value)
      };

      if (params.view_count < 0 || !e.target.value) {
        this.$alert.danger(this.$t("round_views_greater"));
        return;
      }

      this.$api.v2base
        .put("/round/view_count", params)
        .then(() => {
          this.$alert.success(this.$t("round_views_success"));
        })
        .catch(() => {
          this.$alert.danger("Server error");
        });

      this.views = Number(e.target.value);
      setTimeout(() => {
        e.target.value = "";
      }, 0);
    },

    changeLikes(e) {
      let params = {
        idt_round: this.id,
        likes_count: Number(e.target.value)
      };

      if (params.likes_count < 0 || !e.target.value) {
        this.$alert.danger(this.$t("round_likes_greater"));
        return;
      }

      this.$api.v2base
        .put("/round/likesCount", params)
        .then(() => {
          this.$alert.success(this.$t("round_likes_success"));
        })
        .catch(() => {
          this.$alert.danger("Server error");
        });

      this.likes = Number(e.target.value);
      setTimeout(() => {
        e.target.value = "";
      }, 0);
    },

    play() {
      if (this.video_link) this.$refs["video"].play();
    },

    pause() {
      if (this.video_link) this.$refs["video"].pause();
    },

    downloadRound() {
      let data = {
        mediaURL: this.media ? this.media.url : this.preview
      };
      fetch("/downloadRound", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(async response => {
        let blob = await response.blob();
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        (a.href = url), (a.download = this.fileName);
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.likes-input
  height: 120%
  max-width: 50%
.user-container
  position: relative
  .checker
    position: absolute
    top: -10px
    right: -5px
</style>
