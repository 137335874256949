var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      staticClass: "modal-sm",
      attrs: { active: _vm.modal, center: true },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "card-body p-4",
          on: {
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _c("mega-switch", {
            staticClass: "w-100 mb-5 bg-success mt-3",
            attrs: {
              label: _vm.$t("round_top_forall"),
              checked: _vm.topForAll
            },
            on: { click: _vm.toggleForAll }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "form-label" }, [
            _vm._v(" " + _vm._s(_vm.$t("round_recommend_number")) + " ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c("mega-range", {
                attrs: { min: 1, max: 100, step: 1 },
                model: {
                  value: _vm.amount,
                  callback: function($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount"
                }
              }),
              _vm._v(" "),
              _c("mega-number", {
                attrs: { min: 1, max: 100, step: 1 },
                model: {
                  value: _vm.amount,
                  callback: function($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "navbar sm-down:flex-column-reverse md-up:justify-content-end"
            },
            [
              _c(
                "mega-button",
                {
                  staticClass: "bg-white sm-down:w-100 md-up:mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "btn-primary sm-down:w-100 sm-down:mb-3",
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }